import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import PropTypes from "prop-types";

const headingVariant = cva("font-tiller", {
  variants: {
    variant: {
      white: "text-white",
      error:
        "bg-gradient-to-b from-white via-red2 via-31% to-red3 bg-clip-text text-transparent drop-shadow-error",
      success:
        "bg-gradient-to-b from-white via-green5 via-31% to-green6 bg-clip-text text-transparent drop-shadow-success",
      gold: "bg-gradient-to-b from-gold1 to-gold2 bg-clip-text text-transparent",
    },
    layout: {
      default: "text-center",
      right: "w-full text-right ml-auto",
    },
    size: {
      default: "text-4xl",
      large: "text-5xl",
      small: "text-2xl",
    },
  },
  defaultVariants: {
    variant: "gold",
    layout: "default",
    size: "default",
  },
});

const Heading = ({ variant, layout, size, className, children, ...props }) => (
  <h2
    className={cn(headingVariant({ variant, layout, size, className }))}
    {...props}
  >
    {children}
  </h2>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  layout: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
};

export default Heading;
