import Panel from "@/components/ui/Panel";
import Image from "next/image";
import PropTypes from "prop-types";
import { useState } from "react";
import ReactPlayer from "react-player/lazy";

export default function VideoPage({ mediaId, thumbnail, children, ...props }) {
  const [videoActive, setVideoActive] = useState(false);

  const closeVideo = () => setVideoActive(false);

  return (
    <>
      <Panel variant="logo" layout="sm" {...props}>
        <main className="relative flex max-w-screen-sm shrink-0 flex-col items-center justify-start gap-4 text-center">
          <div
            className="relative cursor-pointer overflow-hidden rounded-2xl border border-gold1"
            onClick={() => setVideoActive(true)}
          >
            <Image
              src={thumbnail}
              alt="Planhart"
              width={334}
              height={214}
              priority={true}
              className="h-[214px] object-cover"
            />

            <div className="absolute bottom-0 left-0 right-0 top-0 m-auto h-20 w-20 bg-play-button hover:bg-play-button-hover hover:drop-shadow-play-button" />
          </div>
          {children}
        </main>
      </Panel>

      {videoActive && (
        <dialog
          className="z-10 flex h-full w-full flex-col items-center justify-center bg-black/50"
          onClose={closeVideo}
          onClick={(e) => e.target === e.currentTarget && closeVideo()}
        >
          <ReactPlayer
            stopOnUnmount={true}
            onEnded={closeVideo}
            url={`https://theory11.wistia.com/medias/${mediaId}`}
            controls={true}
            autoPlay={true}
            height="90dvh"
            width="50.625dvh"
            config={{
              options: {
                autoPlay: true,
                fullscreenOnRotateToLandscape: false,
                playerColor: "#B98F4B",
              },
              wistia: {},
            }}
          />
        </dialog>
      )}
    </>
  );
}

VideoPage.propTypes = {
  children: PropTypes.node.isRequired,
  mediaId: PropTypes.string.isRequired,
  thumbnail: PropTypes.object.isRequired,
};
