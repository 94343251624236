import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import PropTypes from "prop-types";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        black:
          "h-12 w-full bg-black px-3 py-4 font-tiller text-base uppercase text-white hover:bg-gold2",
        gold: " h-12 w-full bg-gradient-to-b from-gold1 to-gold2 px-3 py-4 font-tiller text-base uppercase text-black hover:text-white hover:border-gold2 hover:bg-gradient-to-b hover:from-black1 hover:to-black2 background",
        planhart:
          " h-[56px_important] w-[296px] px-3 py-4 font-tiller uppercase text-maroon bg-planhart-button hover:bg-planhart-button-hover bg-no-repeat bg-center hover:text-white text-[22px] tracking-widest",
        success:
          " h-[56px_important] w-[296px] px-3 py-4 font-tiller uppercase text-white bg-success-button bg-no-repeat bg-center hover:text-white text-[22px] tracking-widest",
        error:
          " h-[56px_important] w-[296px] px-3 py-4 font-tiller uppercase text-white bg-error-button bg-no-repeat bg-center hover:text-white text-[22px] tracking-widest",

        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-12 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-14 px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.propTypes = {
  asChild: PropTypes.bool.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
};
Button.displayName = "Button";

export { Button, buttonVariants };
