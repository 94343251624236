import { Button } from "@/components/ui/Button";
import Fade from "@/components/ui/Fade";
import Panel from "@/components/ui/Panel";
import TextInput from "@/components/ui/TextInput";
import dataset from "@/lib/dataset";
import { RegExpMatcher, englishRecommendedTransformers } from "obscenity";
import PropTypes from "prop-types";
import { useState } from "react";

export default function NameForm({ onSubmit, children, variant, ...props }) {
  const [name, setName] = useState("");

  const [showError, setShowError] = useState(false);

  const matcher = new RegExpMatcher({
    ...dataset.build(),
    ...englishRecommendedTransformers,
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (name.length > 0 && !matcher.hasMatch(name)) {
      onSubmit(name);
    } else {
      setShowError(true);
    }
  };

  return (
    <Panel
      variant={variant === "planhart" ? "logo" : "default"}
      layout={variant === "planhart" ? "sm" : "default"}
      {...props}
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-10">
        <header className="flex flex-col items-center justify-between gap-4 self-stretch">
          {children}
        </header>
        <main>
          <TextInput
            name={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Your First Name"
            value={name}
            variant={variant === "planhart" ? "default" : "gray"}
            pattern="[a-zA-Z]+"
            title="Letters only"
          />

          {showError && (
            <Fade
              isVisible={showError}
              className="mt-9 w-full rounded-lg bg-red-700 text-center text-xl"
            >
              <div className="px-4 py-2">
                Hmmmm... that doesn&apos;t sound like your real name
              </div>
            </Fade>
          )}
        </main>
        <footer className="flex justify-center">
          <Button
            type="submit"
            size={variant === "planhart" ? "lg" : "default"}
            variant={variant === "planhart" ? "planhart" : "gold"}
          >
            Submit
          </Button>
        </footer>
      </form>
    </Panel>
  );
}

NameForm.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};
