import Header from "@/components/Header";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import PropTypes from "prop-types";
const panelVariant = cva(
  "max-w-full sm:max-w-screen-sm w-full flex-col flex rounded-3xl ring-1 border items-center overflow-hidden backdrop-blur-sm",
  {
    variants: {
      variant: {
        default: "pt-10",
        logo: "-mt-[145px] pt-[100px]",
      },
      layout: {
        default: "px-6 pb-12 gap-10 sm:px-10",
        sm: "px-4 sm:px-10 pb-4 gap-4",
        gapless: "p-0 gap-0",
      },
      state: {
        default: "border-gold2 bg-[black]/50",
        success: "border-green3 bg-green4/75",
        error: "border-red1 bg-maroon3/75",
      },
    },
    defaultVariants: {
      variant: "default",
      layout: "default",
      state: "default",
    },
  },
);

const Panel = ({ variant, layout, state, children, className, ...props }) => {
  return (
    <>
      {(variant ?? "default") === "logo" && <Header variant="planhart" />}
      <div
        className={cn(panelVariant({ variant, layout, state, className }))}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  layout: PropTypes.string,
  state: PropTypes.string,
  variant: PropTypes.string.isRequired,
};
export default Panel;
