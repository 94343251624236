import { cn } from "@/lib/utils";
import PropTypes from "prop-types";

const Eyebrow = ({ className, children }) => (
  <h3
    className={cn(
      "text-center font-tiller uppercase tracking-wider",
      className,
    )}
  >
    {children}
  </h3>
);

Eyebrow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Eyebrow;
