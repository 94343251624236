import { cva } from "class-variance-authority";
import PropTypes from "prop-types";
import * as React from "react";

import { cn } from "@/lib/utils";

const textinputVariants = cva("text-gray-900 block w-full", {
  variants: {
    variant: {
      default:
        "max-w-96 rounded-2xl border border-gold3 bg-maroon p-3 text-center font-tiller text-3xl leading-none text-white shadow-largeInput placeholder:text-white placeholder:opacity-50 focus:outline-none",
      gray: "rounded-lg border border-gold2 bg-neutral-900 p-4 text-center font-tiller text-2xl text-white shadow-largeInput placeholder:text-gray focus:outline-none",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const TextInput = React.forwardRef(({ className, variant, ...props }, ref) => {
  return (
    <input
      className={cn(textinputVariants({ variant, className }))}
      ref={ref}
      {...props}
    />
  );
});

TextInput.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
};
TextInput.displayName = "TextInput";

export default TextInput;
