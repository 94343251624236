import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const Fade = ({ isVisible, variant, className, duration, children }) => {
  const fadeVariant = cva(
    `transition-opacity duration-${duration} flex max-h-full max-w-full shrink flex-col items-center justify-center`,
    {
      variants: {
        variant: {
          default: "relative",
          full: "absolute bottom-0 left-0 right-0 top-0 p-2",
        },
      },
      defaultVariants: {
        variant: "default",
      },
    },
  );

  const [isMounted, setIsMounted] = useState(false);

  duration ??= 500;

  useEffect(() => {
    if (isVisible) {
      setIsMounted(true);
    }
  }, [isVisible]);

  return (
    <div
      className={cn(
        fadeVariant({ variant, className }),
        isVisible ? "z-10 opacity-100" : "-z-1 opacity-0",
      )}
      onAnimationEnd={() => {
        if (!isVisible) {
          setIsMounted(false);
        }
      }}
    >
      {isVisible && isMounted && children}
    </div>
  );
};

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  duration: PropTypes.number,
  isVisible: PropTypes.bool,
  variant: PropTypes.string,
};

export default Fade;
