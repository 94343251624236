import Cookies from "js-cookie";
import { useCallback, useState } from "react";

const defaultOptions = { sameSite: "strict", expires: 365 };
export default function useCookie(name, defaultValue) {
  const [value, setValue] = useState(() => {
    const cookie = Cookies.get(name);
    if (cookie) {
      try {
        // Attempt to parse JSON if the cookie is a stringified object
        return JSON.parse(cookie);
      } catch (error) {
        // If parsing fails, return the raw cookie string
        return cookie;
      }
    }
    // If no cookie exists, set and return the default value
    Cookies.set(name, JSON.stringify(defaultValue), defaultOptions);
    return defaultValue;
  });
  const updateCookie = useCallback(
    (newValue, options) => {
      // Stringify the value before storing it
      Cookies.set(name, JSON.stringify(newValue), {
        ...defaultOptions,
        ...options,
      });
      setValue(newValue);
    },
    [name],
  );
  const deleteCookie = useCallback(() => {
    Cookies.remove(name);
    setValue(null);
  }, [name]);
  return [value, updateCookie, deleteCookie];
}
