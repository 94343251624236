import { blocklist } from "@/data/profanity.yml";
import { DataSet, englishDataset, pattern } from "obscenity";
const dataset = new DataSet();

dataset.addAll(englishDataset);

blocklist.forEach((word) => {
  dataset.addPhrase((phrase) =>
    phrase.setMetadata({ originalWord: word }).addPattern(pattern`${word}`),
  );
});

export default dataset;
