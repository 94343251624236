import boxTwoLogo from "@/images/logo/logo.png";
import planhartLogo from "@/images/logo/planhart-logo.png";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import Image from "next/image";
import PropTypes from "prop-types";

const headerVariant = cva("font-tiller", {
  variants: {
    variant: {
      planhart: "z-10 flex items-center",
      boxtwo: "",
    },
    layout: {
      default: "flex items-center justify-center",
    },
  },
  defaultVariants: {
    variant: "boxtwo",
    layout: "default",
  },
});

const image = (variant) => {
  variant ??= "boxtwo";

  switch (variant) {
    case "planhart":
      return (
        <Image
          src={planhartLogo}
          alt="Planhart Cortex Conclave"
          width={320}
          priority
        />
      );

    default:
      return <Image src={boxTwoLogo} alt="Box Two" width={248} priority />;
  }
};

const Header = ({ variant, layout, className, ...props }) => (
  <header
    className={cn(headerVariant({ variant, layout, className }))}
    {...props}
  >
    {image(variant)}
  </header>
);

Header.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.string,
  variant: PropTypes.string,
};

export default Header;
