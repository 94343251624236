import PropTypes from "prop-types";
const PageWrapper = ({ children }) => (
  <div className="z-1 relative flex h-dvh flex-col justify-between gap-10 overflow-y-auto px-4 py-8 font-[family-name:var(--font-jost)]">
    {children}
  </div>
);

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
